import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  Button,
  MediaPlanFormClone,
  MediaPlanFormCommercialFormChange,
  MediaPlanFormCustomFormChange,
  MediaPlanFormExternalTimeFormChange,
  MediaPlanFormFaceSwapFormChange,
  MediaPlanFormGeneralInfo,
  ModalDialog,
} from "../containers";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import * as api from "../api";
import PropTypes from "prop-types";
import MediaPlanFormCreate from "./MediaPlanFormCreate";
import {Preloader} from "../components";

import {getSeconds, parseTime} from "../utils/helpers";
import {I18n} from "../utils/i18n";
import {TimePicker} from "antd";
import moment from "moment";

import "antd/dist/antd.css";

const timeDurationFormat = 'HH:mm';
const defaultStaticDuration = moment('00:01', timeDurationFormat);

const grid = 8;
const convertDuration = (d) => {
  let res = "00h00m00s";
  let match = d.split(":");
  if (match.length === 3) res = match[0] + "h" + match[1] + "m" + match[2] + "s";
  if (match.length === 2) res = match[0] + "h" + match[1] + "m00s";
  if (match.length === 1) res = match[0] + "h00m00s";
  return res;
};
const recalculate = (list, start_time) => {
  const result = Array.from(list);
  for (let i = 0; i <= result.length - 1; i++) {
    result[i].id = `item-${i + 1}`;
    let st = (i > 0) ? parseTime(result[i - 1].end_time) : new Date(start_time);
    result[i].start_time = st.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    let et = new Date(st);
    let duration = convertDuration(result[i].duration)
    et.setSeconds(et.getSeconds() + getSeconds(duration));
    result[i].end_time = et.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  }

  return result;
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const getItemStyle = (isDragging, draggableStyle, type) => ({
  position: "relative",
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  minWidth: (type === "commercial" || type === "external") ? "225px" : type === "faceswap" ? "225px" : "165px",
  width: (type === "commercial" || type === "external") ? "225px" : type === "faceswap" ? "300px" : "165px",

  // change background colour if dragging
  border: `1px dashed ${isDragging ? 'lightgreen' : 'grey'}`,

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = isDraggingOver => ({
  border: `1px dashed ${isDraggingOver ? 'lightblue' : 'lightgrey'}`,
  display: 'flex',
  padding: grid,
  overflow: 'auto',
  minHeight: "120px",
});

class MediaPlanFormUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: props.mediaplan.meta.name,
        start_time: new Date(new Date().toDateString() + " " + props.mediaplan.spec.start_time),
        end_time: new Date(new Date().toDateString() + " " + props.mediaplan.spec.end_time),
        blocks: (props.mediaplan.spec.items || []).map((item, index) => {
          let res = {...item};
          res.id = `item-${index + 1}`;
          let dt = new Date(1970, 0, 1); // Epoch
          dt.setSeconds(getSeconds(item.duration));
          res.duration = dt.toLocaleTimeString();
          res.selected = false;
          return res
        }),
      },
      error: {
        interval: "",
        timeline: ""
      },
      item: null,
      static_duration: defaultStaticDuration,
      type: null,
      pending: true,
      remove: false,
      tags: [],
      mapTags: [],
      selected: [],
    };
  }

  componentDidMount() {
    api.Tag.List()
      .then((res) => {
        let mapTags = {};
        res.forEach(item => {
          mapTags[item.id] = item.name;
        })
        this.setState({pending: false, tags: res, mapTags: mapTags})
      });
  }

  handleNew = (e, type) => this.setState({type: type});

  handleApply = (e) => {

    let data = {...this.state.data};

    data.start_time = data.start_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    data.end_time = data.end_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    let obj = {
      name: data.name,
      start_time: data.start_time,
      end_time: data.end_time,
      items: [],
    };

    obj.items = data.blocks.map((item) => {
      let elem = {
        type: item.type,
        start_time: item.start_time,
        end_time: item.end_time,
      };

      elem.filename = item.filename;
      elem.media_type = item.media_type;
      elem.duration = convertDuration(item.duration);
      elem.file = item.file;

      if (item.type === "commercial") {
        elem.tags = item.tags;
      }
      if (item.type === "faceswap") {
        elem.layout_file = item.layout_file
        elem.layout_name = item.layout_name
      }
      return elem
    });
    return Promise.resolve(this.props.onSubmit(obj))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    this.setState({error: e, pending: false});
  };

  handleBack = (e) => this.props.onCancel();

  handleRemove = () => {
    this.setState({remove: true})
  };

  handleRemoveCancel = () => {
    this.setState({remove: false})
  }

  handleRemoveApply = () => {
    this.props.onRemove(this.props.mediaplan.meta.id);
  }

  handleClone = () => {
    this.setState({type: "clone"})
  };

  handleClear = () => {
    this.setState({
      selected: [],
      type: null,
      data: {
        ...this.state.data,
        blocks: [],
      }
    })
  };

  handleCloneCancel = () => {
    let blocks = this.state.data.blocks;

    blocks = blocks.map(item => {
      item.selected = false;
      return item;
    })

    this.setState({
      selected: [],
      type: null,
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      }
    })
  }

  handleCloneApply = (items) => {
    let blocks = this.state.data.blocks;
    let id = this.state.data.blocks.length;

    blocks = blocks.map(item => {
      item.selected = false;
      return item;
    })

    items.forEach(item => {
      let el = {...item}
      ++id;
      el.id = `item-${id}`
      blocks.push(el);
    })

    this.setState({
      selected: [],
      type: null,
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      }
    });
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let patch = {};
    switch (name) {
      case 'mediaplan_info':
        patch = {
          data: {
            ...this.state.data,
            ...value,
            blocks: recalculate(this.state.data.blocks, value.start_time),
          }
        };
        break;
      default:
    }

    this.setState({...patch})
  };

  handleCancel = () => this.setState({type: null, item: null});

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;

    const blocks = reorder(this.state.data.blocks, result.source.index, result.destination.index);

    this.setState({
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      }
    });
  };

  handleSelectCommercialItem = (e, item) => {
    this.setState({type: "commercial", item: item})
  };

  handleSelectExternalItem = (e, item) => {
    this.setState({type: "external", item: item})
  };

  handleSelectFaceSwapItem = (e, item) => {
    this.setState({type: "faceswap", item: item})
  };

  handleSelectContent = (item) => {

    if (item.meta.type === "static") {
      this.setState({item: item})
      this.modalBtn.click()
      return
    }

    let dt = new Date(1970, 0, 1); // Epoch
    dt.setSeconds(getSeconds(item.meta.duration));
    let duration = dt.toLocaleTimeString();
    const id = this.state.data.blocks.length + 1;
    let blocks = this.state.data.blocks;
    blocks.push({
      id: `item-${id}`,
      type: "media",
      media_type: item.meta.type,
      name: item.meta.name,
      filename: item.meta.name,
      file: item.meta.id,
      duration: duration,
    });

    this.setState({
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      },
      type: null,
      item: null
    })
  };

  handleSetCommercialBlockContent = (item) => {
    const id = this.state.data.blocks.length + 1;
    let blocks = this.state.data.blocks;
    if (!!item.id && blocks.length > 0) {
      for (let i = 0; i <= blocks.length - 1; i++) {
        if (blocks[i].id === item.id) {
          blocks[i] = {...blocks[i], ...item};
          break;
        }
      }
    } else {
      blocks.push({
        id: `item-${id}`,
        type: "commercial",
        tags: item.tags,
        duration: item.duration,
      });
    }

    this.setState({
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      },
      type: null,
      item: null
    })
  };

  handleSetExternalTimeBlockContent = (item) => {
    const id = this.state.data.blocks.length + 1;
    let blocks = this.state.data.blocks;
    if (!!item.id && blocks.length > 0) {
      for (let i = 0; i <= blocks.length - 1; i++) {
        if (blocks[i].id === item.id) {
          blocks[i] = {...blocks[i], ...item};
          break;
        }
      }
    } else {
      blocks.push({
        id: `item-${id}`,
        type: "external",
        media_type: item.media_type,
        duration: item.duration,
      });
    }

    this.setState({
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      },
      type: null,
      item: null
    })
  };

  handleSetFaceSwapBlockContent = (item) => {
    const id = this.state.data.blocks.length + 1;
    let blocks = this.state.data.blocks;
    if (!!item.id && blocks.length > 0) {
      for (let i = 0; i <= blocks.length - 1; i++) {
        if (blocks[i].id === item.id) {
          const block = {
            ...item,
            media_type: item.file.type,
            name: item.file.name,
            filename: item.file.name,
            file: item.file.id,
            layout_file: item.layout_file.id,
            layout_name: item.layout_file.name,
            duration: item.duration,
          }
          blocks[i] = {...blocks[i], ...block};
          break;
        }
      }
    } else {
      blocks.push({
        id: `item-${id}`,
        type: "faceswap",
        media_type: item.file.type,
        name: item.file.name,
        filename: item.file.name,
        file: item.file.id,
        layout_file: item.layout_file.id,
        layout_name: item.layout_file.name,
        duration: item.duration,
      });
    }

    this.setState({
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      },
      type: null,
      item: null
    })

  }

  handleSelect = (e, index) => {
    const target = e.target;

    let blocks = this.state.data.blocks;
    let selected = this.state.selected;
    const el = {...blocks[index]};
    blocks[index].selected = !blocks[index].selected;

    if (target.checked) {
      selected.push(el);
    } else {
      selected = selected.filter(e => e.id !== el.id)
    }

    this.setState({
      selected: selected,
      data: {
        ...this.state.data,
        blocks: blocks,
      }
    })
  }

  handleUnselectContent = (e, index) => {
    let blocks = this.state.data.blocks;
    let selected = this.state.selected;

    const el = {...blocks[index]};
    selected = selected.filter(e => e.id !== el.id)

    blocks.splice(index, 1);

    this.setState({
      selected: selected,
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      }
    })
  };

  handleDisabled = () => {
    if (!!this.state.pending) return true;
    if (!this.state.data.start_time || !this.state.data.end_time) return true;

    let curr_time = new Date();
    let start_time = new Date();
    let end_time = new Date();

    start_time.setHours(this.state.data.start_time.getHours(), this.state.data.start_time.getMinutes(), this.state.data.start_time.getSeconds());
    end_time.setHours(this.state.data.end_time.getHours(), this.state.data.end_time.getMinutes(), this.state.data.end_time.getSeconds());

    if (start_time.getTime() >= end_time.getTime()) return true;

    if (!!this.state.data.blocks.length) {
      let lastElem = parseTime(this.state.data.blocks[this.state.data.blocks.length - 1].end_time);
      curr_time.setHours(lastElem.getHours(), lastElem.getMinutes(), lastElem.getSeconds());

      if (curr_time.getTime() > end_time.getTime()) {
        return true
      }

      if (this.state.data.blocks.length > 1) {
        let invalid = false;
        for (let i = 1; i <= this.state.data.blocks.length - 1; i++) {
          let prevElem = parseTime(this.state.data.blocks[i - 1].end_time);
          let currElem = parseTime(this.state.data.blocks[i].end_time);
          if (prevElem.getTime() > currElem.getTime()) {
            invalid = true;
            break;
          }
        }

        if (invalid) {
          return true
        }
      }
    }

    return false;
  };

  handleStaticDataSet = (e) => {
    this.setState({item: e.target.value.static, static_duration: e.target.value.duration}, this.handleDateSet)
  }

  handleSelectStaticContent = (e, item) => {
    this.setState({item: item, static_duration: moment(item.duration, timeDurationFormat)})
    this.modalBtn.click()
  }

  handleDateSet = () => {
    let {item, static_duration} = this.state;

    const id = this.state.data.blocks.length + 1;
    let blocks = this.state.data.blocks;
    blocks.push({
      id: `item-${id}`,
      type: "media",
      media_type: item.meta.type,
      name: item.meta.name,
      filename: item.meta.name,
      file: item.meta.id,
      duration: new Date(static_duration).toLocaleTimeString(),
    });
    this.setState({
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      },
      type: null,
      static_duration: defaultStaticDuration,
      item: null
    })
  }

  handleSetStaticBlockContent = () => {
    let {item, static_duration} = this.state;
    let blocks = this.state.data.blocks;
    if (!!item.id && blocks.length > 0) {
      for (let i = 0; i <= blocks.length - 1; i++) {
        if (blocks[i].id === item.id) {
          item.duration = new Date(static_duration).toLocaleTimeString()
          blocks[i] = {...blocks[i], ...item};
          break;
        }
      }
    }
    this.setState({
      data: {
        ...this.state.data,
        blocks: recalculate(blocks, this.state.data.start_time),
      },
      type: null,
      item: null
    })
  }

  handleDateUnset = () => {
    this.setState({item: null, static_duration: defaultStaticDuration})
  }

  staticDurationSettings = () => {
    if (!this.state.item) return <div/>;
    return (
      <div className="d-flex justify-content-center">
        <TimePicker
          format={timeDurationFormat}
          inputReadOnly={true}
          allowClear={false}
          value={moment(this.state.static_duration, timeDurationFormat)}
          defaultValue={defaultStaticDuration}
          defaultOpenValue={defaultStaticDuration}
          onChange={val => {
            let date = !!val ? val.toDate() : null;
            this.setState({static_duration: date})
          }}
        />
      </div>
    )
  }

  setModal = (ref) => {
    this.modalBtn = ref
  }

  showTags = (list) => {
    let res = [];
    list.forEach(item => res.push(this.state.mapTags[item]))
    return res.join(", ");
  }

  render() {

    if (this.state.pending) return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </div>
    );

    const {mediaplan} = this.props;

    window.$(() => window.$('[data-toggle="popover"]').popover({trigger: 'hover'}));

    if (!!this.state.remove) {
      return (
        <section className="container content">
          <div className="row justify-content-center">
            <div className="col-4 text-center">
              <div><h3>{I18n.t('key_MediaPlanFormUpdate_RemoveConfirm', {name: mediaplan.meta.name})}</h3></div>
            </div>
          </div>

          <div className="row justify-content-center mt-2">
            <div className="col-4">
              <div className="clearfix">
                <div className="float-left">
                  <button className="btn btn-sm btn-secondary"
                          onClick={this.handleRemoveCancel}>{I18n.t('key_Cancel')}</button>
                </div>
                <div className="float-right">
                  <button className="btn btn-sm btn-danger"
                          onClick={this.handleRemoveApply}>{I18n.t('key_Remove')}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }

    if (this.state.type === "commercial") return (
      <div className="row">
        <div className="col-6 mx-auto">
          <div className="mb-2">
            <h4>{I18n.t('key_MediaPlanFormUpdate_CommercialInfo')}</h4>
          </div>
          <MediaPlanFormCommercialFormChange data={this.state.item}
                                             tags={this.state.tags}
                                             limit={0}
                                             onSubmit={this.handleSetCommercialBlockContent}
                                             onCancel={this.handleCancel}/>
        </div>
      </div>
    )

    if (this.state.type === "external") return (
      <div className="row">
        <div className="col-6 mx-auto">
          <div className="mb-2">
            <h4>{I18n.t('key_MediaPlanFormUpdate_ExternalSourceInfo')}</h4>
          </div>
          <MediaPlanFormExternalTimeFormChange data={this.state.item}
                                               limit={0}
                                               onSubmit={this.handleSetExternalTimeBlockContent}
                                               onCancel={this.handleCancel}/>
        </div>
      </div>
    )

    if (this.state.type === "faceswap") return (
      <div className="row">
        <div className="col-6 mx-auto">
          <div className="mb-2">
            <h4>{I18n.t('key_MediaPlanFormUpdate_FaceSwapSourceInfo')}</h4>
          </div>
          <MediaPlanFormFaceSwapFormChange data={this.state.item}
                                           limit={0}
                                           onSubmit={this.handleSetFaceSwapBlockContent}
                                           onCancel={this.handleCancel}/>
        </div>
      </div>
    )

    return (
      <div>

        <div className="row justify-content-center mb-4">
          <div className="col-4 border-right-1">
            <div className="mb-4">
              <div className="mb-2">
                <h4>{I18n.t('key_MediaPlanFormUpdate_MediaFileTitle')}</h4>
              </div>

              <MediaPlanFormCustomFormChange onDateSet={this.handleStaticDataSet}
                                             onSubmit={this.handleSelectContent}
                                             onCancel={this.handleCancel}/>
            </div>

            <div className="mb-4">
              <div className="mb-2">
                <h4>{I18n.t('key_MediaPlanFormUpdate_CommercialTitle')}</h4>
              </div>

              <div>
                <button className="btn btn-primary"
                        onClick={e => this.handleNew(e, "commercial")}>{I18n.t('key_Add')}</button>
              </div>
            </div>

            <div className="mb-4">
              <div className="mb-2">
                <h4>{I18n.t('key_MediaPlanFormUpdate_ExternalSourceTitle')}</h4>
              </div>

              <div>
                <button className="btn btn-primary"
                        onClick={e => this.handleNew(e, "external")}>{I18n.t('key_Add')}</button>
              </div>
            </div>

            <div className="mb-4">
              <div className="mb-2">
                <h4>{I18n.t('key_MediaPlanFormUpdate_FaceSourceTitle')}</h4>
              </div>

              <div>
                <button className="btn btn-primary"
                        onClick={e => this.handleNew(e, "faceswap")}>{I18n.t('key_Add')}</button>
              </div>
            </div>

          </div>

          <div className="col-8">
            {(this.state.type === null) && (
              <div>
                <div className="mb-2">
                  <h4>{I18n.t('key_MediaPlanFormUpdate_GeneralInfo')}</h4>
                </div>
                <MediaPlanFormGeneralInfo name="mediaplan_info" mediaplan={mediaplan} onChange={this.handleChange}/>
              </div>
            )}

            {(this.state.type === "clone") && (
              <div>
                <div className="mb-2">
                  <h4>{I18n.t('key_MediaPlanFormUpdate_CloneContent')}</h4>
                </div>

                <MediaPlanFormClone data={this.state.selected}
                                    mapTags={this.state.mapTags}
                                    onSubmit={this.handleCloneApply}
                                    onCancel={this.handleCloneCancel}/>
              </div>
            )}
          </div>
        </div>

        <div>
          {
            (this.state.type === null) && (
              <div>

                <div className="d-flex flex-row-reverse my-2" style={{minHeight: "20px"}}>
                  <div className="px-2">
                    <span className="cursor-pointer text-primary" onClick={this.handleClear}>
                      {I18n.t('key_MediaPlanFormUpdate_ClearBlocks')}
                    </span>
                  </div>
                  <div className="px-2">
                    {(this.state.type !== 'clone' && this.state.selected.length > 0) && (
                      <span className="cursor-pointer text-primary" onClick={this.handleClone}>
                        {I18n.t('key_MediaPlanFormUpdate_CopyBlocks', {count: this.state.selected.length})}
                      </span>
                    )}
                  </div>
                </div>

                <div className="text-center">
                  {(!!this.state.error.timeline) && (
                    <span className="text-danger">{this.state.error.timeline}</span>
                  )}
                </div>

                <div>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef}
                             style={getListStyle(snapshot.isDraggingOver)}
                             {...provided.droppableProps}>

                          {!this.state.data.blocks.length && (
                            <div className="w-100 text-center align-self-center">
                              <span>{I18n.t('key_MediaPlanFormUpdate_ScheduleListEmpty')}</span>
                            </div>
                          )}

                          {this.state.data.blocks.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}
                                       isDragDisabled={this.state.type === 'clone'}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                                     className={`
                                       ${item.type === "commercial"
                                       ? "border-info"
                                       : item.type === "external"
                                         ? "border-warning"
                                         : item.type === "faceswap"
                                           ? "border-dark"
                                           : item.type === "default"
                                             ? "border-danger"
                                             : (item.type === "media" && item.media_type === "static")
                                               ? "border-primary" : ""}
                                     `}
                                     style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.type)}>

                                  <div>
                                    <div>
                                      <div className="text-truncate font-weight-bold">

                                        {(item.type === "default") && (
                                          <span>{I18n.t('key_MediaPlanFormUpdate_DefaultTitle')}</span>
                                        )}

                                        {(item.type === "external") && (
                                          <div>{I18n.t('key_MediaPlanFormUpdate_ExternalSourceTimeTitle')}</div>
                                        )}

                                        {(item.type === "faceswap") && (
                                          <div>{I18n.t('key_MediaPlanFormUpdate_FaceSwapSourceTimeTitle')}</div>
                                        )}
                                      </div>
                                      <div className="text-truncate">{item.start_time}-{item.end_time}</div>
                                    </div>

                                    <div style={{position: "absolute", bottom: "5px", right: "0px"}}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`item-${index}`}
                                               disabled={this.state.type === 'clone'}
                                               checked={item?.selected || false}
                                               onChange={e => this.handleSelect(e, index)}/>
                                        <label className="custom-control-label" htmlFor={`item-${index}`}/>
                                      </div>
                                    </div>

                                    <div style={{position: "absolute", top: "5px", right: "7px"}}>
                                      {
                                        this.state.type !== 'clone'
                                          ? <i className="fa fa-times cursor-pointer"
                                               onClick={e => this.handleUnselectContent(e, index)}/>
                                          : <i className="fa fa-times cursor-not-allowed"/>
                                      }
                                    </div>

                                    <div className="row">
                                      <div className={item.type === "faceswap" ? "col" : "col-12"}>
                                        {item.type === "faceswap" && (
                                          <div>{I18n.t('key_MediaPlanFormUpdate_ContentTitle')}</div>
                                        )}
                                        {(item.type !== "commercial" && item.type !== "external") && (
                                          <span>{item.filename}</span>
                                        )}

                                        {(item.type === "commercial") && (
                                          <div>{I18n.t('key_MediaPlanFormUpdate_CommercialTimeTitle')} <i
                                            className="fas fa-info-circle"
                                            data-toggle="popover"
                                            title={I18n.t('key_MediaPlanFormUpdate_Tags')}
                                            data-content={this.showTags(item.tags)}/>
                                          </div>
                                        )}
                                      </div>

                                      {(item.type === "faceswap") && (
                                        <div className="col border-left mx-3 px-3">
                                          <div>{I18n.t('key_MediaPlanFormUpdate_LayoutTitle')}</div>
                                          <div>{item.layout_name}</div>
                                        </div>
                                      )}
                                    </div>

                                    <div className="pt-2">
                                      {(item.type === "media" && item.media_type === "static") && (
                                        <div className="text-center mt-1">
                                          <button className="btn btn-primary btn-sm"
                                                  onClick={e => this.handleSelectStaticContent(e, item)}>
                                            {I18n.t('key_Change')}
                                          </button>
                                        </div>
                                      )}

                                      {(item.type === "commercial") && (
                                        <div className="text-center mt-1">
                                          <button className="btn btn-primary btn-sm"
                                                  onClick={e => this.handleSelectCommercialItem(e, item)}>
                                            {I18n.t('key_Change')}
                                          </button>
                                        </div>
                                      )}

                                      {(item.type === "external") && (
                                        <div className="text-center mt-1">
                                          <button className="btn btn-primary btn-sm"
                                                  onClick={e => this.handleSelectExternalItem(e, item)}>
                                            {I18n.t('key_Change')}
                                          </button>
                                        </div>
                                      )}

                                      {(item.type === "faceswap") && (
                                        <div className="text-center mt-1">
                                          <button className="btn btn-primary btn-sm"
                                                  onClick={e => this.handleSelectFaceSwapItem(e, item)}>
                                            {I18n.t('key_Change')}
                                          </button>
                                        </div>
                                      )}
                                    </div>

                                  </div>

                                </div>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}

                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <button className="btn btn-secondary"
                            onClick={this.handleBack}>{I18n.t('key_Back')}
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-danger"
                            onClick={this.handleRemove}>{I18n.t('key_Remove')}
                    </button>
                  </div>
                  <div>
                    <Button type='submit'
                            className="btn btn-primary"
                            disabled={this.handleDisabled()}
                            submit={this.handleApply}
                            main={I18n.t('key_Apply')}/>
                  </div>
                </div>

              </div>
            )
          }

        </div>

        <ModalDialog name="static_change"
                     contentClassName="modal-content violet"
                     headerContent={I18n.t('key_MediaPlanFormUpdate_StaticModalHeaderSettings')}
                     btnOpen={<button data-toggle="modal"
                                      data-target="#static_change"
                                      ref={ref => this.setModal(ref)}
                                      className="btn btn-primary btn-sm hidden">
                       {I18n.t('key_Change')}
                     </button>}
                     btnCloseName={I18n.t('key_Close')}
                     content={this.staticDurationSettings()}
                     onOk={this.handleSetStaticBlockContent}
                     onClose={this.handleDateUnset}
                     btnOkName={I18n.t('key_Change')}
        />
      </div>
    );

  }
}

MediaPlanFormCreate.propTypes = {
  mediaplan: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(MediaPlanFormUpdate);
