import React from "react"
import PropTypes from "prop-types"
import * as api from "../api";
import {ModalDialog} from "../containers";
import {connect} from "react-redux";
import {I18n} from "../utils/i18n";
import {TimePicker} from "antd";

import moment from "moment";

import "antd/dist/antd.css";

const timeFormat = 'HH:mm';
const defaultStaticDuration = moment('00:01', timeFormat);

class MediaPlanFormCustomFormChange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      media: {
        items: [],
        total: 0,
      },
      static_duration: defaultStaticDuration,
      filter: {
        video: false,
        static: false,
      },
      pending: false,
      items: [],
      item: null,
    };
  };

  componentDidMount() {
    api.Media.FileList({all: true})
      .then((res) => {
        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            items: (res.items || []).filter(this.fileFilter)
          }
        })
      })
      .catch(() => {
      });
  }

  fileFilter = (item) => item.status.state === "ready";

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let patch = {};
    switch (name) {
      case 'static_filter':
        patch = {filter: {...this.state.filter, static: value}};
        break;
      case 'video_filter':
        patch = {filter: {...this.state.filter, video: value}};
        break;
      default:
    }

    this.setState({...patch})
  };

  handleAdd = (e, file) => {
    e.preventDefault();
    if (file.meta.type === "static") {
      this.setState({item: file})
      this.modalBtn.click()
      return
    }
    this.props.onSubmit(file)
  };

  handleDisabled = () => {
    return this.state.pending || !this.state.data.file;
  };

  handleDateSet = () => {
    let event = new Event("static_duration");
    Object.defineProperty(event, 'target', {
      writable: false,
      value: {name: "static_duration", value: {static: this.state.item, duration: this.state.static_duration}},
    });
    this.props.onDateSet(event)
    this.setState({item: null, static_duration: defaultStaticDuration})
  };

  handleDateUnset = (e) => {
    this.setState({static_duration: defaultStaticDuration})
  };

  staticDurationSettings = () => {
    return (
      <div className="d-flex justify-content-center">
        <TimePicker
          format={timeFormat}
          inputReadOnly={true}
          allowClear={false}
          value={moment(this.state.static_duration, timeFormat)}
          defaultValue={defaultStaticDuration}
          defaultOpenValue={defaultStaticDuration}
          onChange={val => {
            let date = !!val ? val.toDate() : null;
            this.setState({static_duration: date})
          }}
        />
      </div>
    )
  }

  handleFilter = (item) => {
    if (!this.state.filter.video && !this.state.filter.static) return true;
    if (item.meta.type === "video" && !!this.state.filter.video) return true;
    if (item.meta.type === "static" && !!this.state.filter.static) return true;
    return ["video", "static"].indexOf(item.meta.type) > -1 && !!this.state.filter.video && !!this.state.filter.static;
  };

  setModal = (ref) => {
    this.modalBtn = ref
  }

  render() {
    return (
      <div>
        <div>

          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="customCheckboxInline1" name="video_filter"
                   checked={this.state.filter.video}
                   onChange={this.handleChange}
                   className="custom-control-input"/>
            <label className="custom-control-label" htmlFor="customCheckboxInline1">
              {I18n.t('key_MediaPlanFormUpdate_MediaFileFilterOnlyVideo')}
            </label>
          </div>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="customCheckboxInline2" name="static_filter"
                   checked={this.state.filter.static}
                   onChange={this.handleChange}
                   className="custom-control-input"/>
            <label className="custom-control-label" htmlFor="customCheckboxInline2">
              {I18n.t('key_MediaPlanFormUpdate_MediaFileFilterOnlyStatic')}
            </label>
          </div>
        </div>

        <div className="list-group list-group-flush back pr-2"
             style={{height: "300px", overflow: "scroll", overflowY: "scroll", overflowX: "hidden"}}>
          {
            (this.state.media.items || []).filter(this.handleFilter).map((item, index) => {
              return (
                <div key={index}
                     style={{height: "45px", minHeight: "45px"}}
                     className="list-group-item d-flex justify-content-between align-items-center p-1">
                  <div className="text-truncate w-100">{index + 1}. {item.meta.name}</div>
                  <div className="ml-1">
                    {(item.meta.type === "static") && <i className="fas fa-2x fa-plus-square cursor-pointer"
                                                         onClick={e => this.handleAdd(e, item)}/>
                    }
                    {(item.meta.type === "video") && <i className="fas fa-2x fa-plus-square cursor-pointer"
                                                        onClick={e => this.handleAdd(e, item)}/>}
                  </div>
                </div>
              )
            })
          }
        </div>

        <ModalDialog name="static_add"
                     contentClassName="modal-content violet"
                     headerContent={I18n.t('key_MediaPlanFormUpdate_StaticModalHeaderSettings')}
                     btnOpen={<i data-toggle="modal"
                                 data-target="#static_add"
                                 ref={ref => this.setModal(ref)}
                                 className="fas fa-2x fa-plus-square cursor-pointer hidden"/>}
                     btnCloseName={I18n.t('key_Close')}
                     content={this.staticDurationSettings()}
                     onOk={this.handleDateSet}
                     onClose={this.handleDateUnset}
                     btnOkName={I18n.t('key_Apply')}
        />
      </div>
    )
  }
}

MediaPlanFormCustomFormChange.propTypes = {
  name: PropTypes.string,
  onDateSet: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(MediaPlanFormCustomFormChange);
